// HomePage.tsx
import './home.css'; 

export default function HomePage() {
    return (
        <div className='homeContainer'>
            <img src="/images/jb1.jpg" alt="Joy Bangla Restaurant Interior" className='restaurantImage'/>
            <div className='textContent'>
                <p>Situated on the edge of Castleford town centre Joy Bangla offers the opportunity to sample the very best Bangladeshi and Indian cuisine without heading to the big city.</p>
                <p>Every Sunday and Wednesday between 5:30pm to 10:00pm we offer a special Banquet Night. You get poppadoms and pickle tray, a starter, a main, a side, and coffee or ice cream.</p>
                <p>We are open Tuesday to Sunday, and every bank holiday, including Christmas Day. Give us a call to ask about booking for Christmas Day and enjoy your Christmas dinner whilst also avoiding the washing up.</p>
                <p>Please note, we are unable to accept credit and debit card payments.</p>
            </div>
        </div>
    );
}
