import React from 'react';
import './footer.css';

type FooterProps = {
  phoneNumber: string;
};

export const Footer: React.FC<FooterProps> = ({ phoneNumber }) => {
  return (
    <footer className="stickyFooter">
      <div className="container">
        <p>
          Call us: 
          <a href={`tel:${phoneNumber.replace(/\s/g, '')}`} className="phoneNumber">
            {phoneNumber}
          </a>
        </p>
      </div>
    </footer>
  );
};
