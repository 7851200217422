import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import HomePage from './pages/home';
import StartersComponent from './pages/starters';
import MainsComponent from './pages/mains';
import SidesComponent from './pages/sides';
import { OpeningHours } from './pages/openinghours';
import { Footer } from './components/footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/starters" element={<StartersComponent />} />
          <Route path="/mains" element={<MainsComponent />} />
          <Route path="/sides" element={<SidesComponent />} />
          <Route path="/opening-hours" element={<OpeningHours hours={openingHours} phoneNumber={phoneNumber} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer phoneNumber={phoneNumber} />
      </BrowserRouter>
    </div>
  );
}

export default App;

function NoMatch() {
  return <h1>404</h1>;
}

// Define types for the opening hours and the phone number
export type OpeningHoursProps = {
  hours: { [day: string]: string };
  phoneNumber: string;
};

const openingHours = {
  Monday: 'Closed (except for Bank Holidays)',
  Tuesday: '17:30 – 23:00',
  Wednesday: '17:30 – 23:00',
  Thursday: '17:30 – 23:00',
  Friday: '17:30 – 23:30',
  Saturday: '17:30 – 23:30',
  Sunday: '17:30 – 23:00',
};

const phoneNumber = '01977 524293';
