import { tMenuItem } from '../types';
import './starters.css';

const startersData: tMenuItem[] = [
    { name: 'Onion Bhaji', description: 'Crispy fried onion balls, seasoned with Indian spices.' },
    { name: 'Pakoras (Chicken or Lamb)', description: 'Deep-fried battered chicken or lamb, infused with traditional herbs.' },
    { name: 'Chicken or Meat Chaat', description: 'Savory street food style dish with tangy spices.' },
    { name: 'Sheek Kebab', description: 'Skewered minced meat with spices, grilled to perfection.' },
    { name: 'King Prawn on Puri', description: 'Juicy prawns served on fluffy, deep-fried bread.' },
    { name: 'Prawn on Puri', description: 'Lightly spiced prawns served on a crisp puri.' },
    { name: 'Vegetable Pakora', description: 'Mixed vegetable fritters, a crunchy vegetarian delight.' },
    { name: 'Samosa (Meat or Veg.)', description: 'Triangular pastry filled with spiced meat or vegetables.' },
    { name: 'Tandoori Chicken', description: 'Yogurt and spice marinated chicken, cooked in a tandoor.' },
    { name: 'Chicken Tikka', description: 'Boneless chicken pieces marinated and cooked in a tandoor.' },
    { name: 'Lamb Tikka', description: 'Succulent pieces of lamb marinated and roasted in a tandoor.' },
    { name: 'Chicken Sheek Kebab', description: 'Minced chicken seasoned with spices and grilled on skewers.' },
];

const joyBanglaStartersData: tMenuItem[] = [
    { name: 'Bangla Chingri', description: 'Giant King Prawns cooked in the shell with garlic, Indian shallots, spice, fenugreek, coriander, spring onion, cocktail sauce. Served with puri bread and salad dressing.' },
    { name: 'Bangla Mix Cocktail', description: 'Selection of traditional starters - chicken tikka, sheek kebab, onion bhaji, chicken pakora and vegetable samosa. (Serves 2 people).' },
    { name: 'Sobli Mass', description: 'Thai fishcakes with shashlik vegetables and coriander salad.' },
    { name: 'Garlic Bahari', description: 'Cooked with fresh garlic, spring onions, shallots, methi, herbs, spices and onions, chicken, lamb tikka or king prawn.' },
    { name: 'Royal Mix', description: 'Selection of starters - vegetable pakoras, vegetable samosa, onion bhaji. Served with salad.' },
    { name: 'Ampara', description: 'Morsels of the finest haddock, lightly spiced with spices and herbs and served with a garlic chilli dip and mint sauce.' },
    { name: 'Garlic Mushrooms', description: 'Cooked with shallots, methi, fresh garlic, fresh mushrooms, spring onion. Served with crispy salad.' },
    { name: 'Special Mix', description: 'The traditional mince of lamb and mince of chicken with the distinct flavours of fresh herbs and aromatic spices.' }
  ];
  
  // Combine both starters into one array before passing to the component or within it
  const combinedStartersData: tMenuItem[] = [
    ...startersData,
    ...joyBanglaStartersData
  ];
  
  // Updated StartersComponent to accept combinedStartersData
  export default function StartersComponent() {
    // Direct mapping to generate cards without rating
    return (
      <div className="startersContainer">
        {combinedStartersData.map((starter, index) => (
          <StarterDishCard 
            key={index} // Best to use a more stable identifier than index if possible
            dishName={starter.name} 
            dishDescription={starter.description} 
          />
        ))}
      </div>
    );
  };
  
  // Simplified StarterDishCard component, removed the rating prop
  const StarterDishCard: React.FC<{ dishName: string; dishDescription: string }> = ({ dishName, dishDescription }) => {
    return (
      <div className="starterDishCard">
        <h3 className="starterDishName">{dishName}</h3>
        <p className="starterDishDescription">{dishDescription}</p>
      </div>
    );
  };
  