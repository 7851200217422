type tSideCategory = {
    name: string;
    items: tSideItem[];
};

type tSideItem = {
    name: string;
    description?: string; // Optional, used for items like "Joy Bangla Nan" which has a description
};

const sidesData: tSideCategory[] = [
    {
        name: 'FRESH SEASONAL VEGETABLES',
        items: [
        { name: 'Bombay Aloo', description: 'Spicy potatoes with a hint of cumin and mustard seeds.' },
        { name: 'Sag Aloo / Bhazi', description: 'Potatoes cooked with spinach, a classic combination.' },
        { name: 'Chana Massala', description: 'Chickpeas simmered in a spicy tomato gravy.' },
        { name: 'Allo Chana', description: 'A tangy mix of potatoes and chickpeas in a light sauce.' },
        { name: 'Tarka Dal', description: 'Lentils tempered with garlic and spices.' },
        { name: 'Mushroom Bhaji', description: 'Mushrooms sautéed with garlic, onion, and tomato.' },
        { name: 'Aloo Gobi', description: 'Cauliflower and potatoes cooked in Indian spices.' },
        { name: 'Cauliflower Bhaji', description: 'Cauliflower florets tossed in a flavorful spice mix.' },
        { name: 'Sag or Motor Panir', description: 'Spinach and peas with homemade cheese cubes.' },
        ],
    },
    {
        name: 'RICE DISHES',
        items: [
            { name: 'Steamed Rice', description: 'Perfectly fluffy and steamed to perfection, a versatile side.' },
            { name: 'Pilau Rice', description: 'Basmati rice cooked with aromatic spices and a hint of saffron.' },
            { name: 'Onion Fried Rice', description: 'Fried rice with caramelized onions adding a sweet depth of flavor.' },
            { name: 'Keema Rice', description: 'Savory minced lamb rice, cooked with peas and aromatic spices.' },
            { name: 'Egg Fried Rice', description: 'Classic egg fried rice, a simple yet satisfying dish.' },
            { name: 'Mushroom Rice', description: 'Earthy mushrooms tossed with seasoned rice.' },
            { name: 'Vegetable Rice', description: 'A medley of fresh vegetables stir-fried with rice.' },
            { name: 'Garlic Aloo Rice', description: 'Garlic-infused rice with a touch of fried potatoes.' },
            { name: 'Special Fried Rice', description: 'Rice fried with a mix of vegetables, eggs, and chicken.' },
            { name: 'Meetha Rice', description: 'Sweet rice dish, flavored with cardamom and nuts.' },
        ],
    },
    {
        name: 'VARIOUS BREAD ESSENTIALS',
        items: [
            { name: 'Nan Bread', description: 'Soft, pillowy flatbread, fresh from the tandoor.' },
            { name: 'Keema Nan', description: 'Nan stuffed with spiced minced lamb for a hearty bite.' },
            { name: 'Peshwari Nan', description: 'Sweet nan filled with nuts and sultanas, a delightful contrast.' },
            { name: 'Cheese Nan', description: 'Melted cheese inside warm nan, a gooey treat.' },
            { name: 'Garlic Nan', description: 'Garlic butter spread over fluffy nan, a fragrant favorite.' },
            { name: 'Kulcha Nan', description: 'Stuffed with a choice of fillings, from potatoes to onions.' },
            {
                name: 'Joy Bangla Nan',
                description: 'Stuffed with lamb tikka chicken, tikka keema cheese, onion, chillies & mixed sauce.'
            },
            { name: 'Garlic and Daniya Nan', description: 'Infused with fresh cilantro and garlic for a zesty kick.' },
            { name: 'Stuffed Paratha', description: 'Layered flatbread with a vegetable stuffing, griddled to perfection.' },
            { name: 'Plain Paratha', description: 'Buttery and flaky flatbread, an irresistible staple.' },
            { name: 'Chapati', description: 'A thin, wheat flatbread, light and healthy.' },
            { name: 'Puri', description: 'Deep-fried and puffed bread, light yet indulgent.' },
            { name: 'Tandoori Roti', description: 'Whole-wheat bread, cooked in the tandoor for a rustic flavor.' },
        ],
    },
    {
        name: 'ENGLISH DISHES',
        items: [
            { name: 'Chicken Nuggets', description: 'Breaded and fried chicken pieces, a classic favorite.  With green salad and French fries.' },
            { name: 'Chicken Omelette', description: 'Fluffy omelette with chicken and a side of fries.' },
            { name: 'Fried Chicken', description: 'Crispy fried chicken, served with fries and salad.' },
        ],
    },
    {
        name: 'ACCOMPANIMENTS',
        items: [
            { name: 'Popadoms (Plain)' },
            { name: 'Popadoms (Spicy)' },
            { name: 'Assorted Pickles & Relishes' },
            { name: 'Raitha (cucumber, onion or mix)' },
            { name: 'Chips' }, 
        ],
    },
];


const SideDishCard: React.FC<{ dishName: string; dishDescription?: string }> = ({ dishName, dishDescription }) => {
    return (
      <div className="starterDishCard"> {/* Reusing the starterDishCard styles */}
        <h3 className="starterDishName">{dishName}</h3>
        {dishDescription && <p className="starterDishDescription">{dishDescription}</p>}
      </div>
    );
  };
  
  const SideDishSection: React.FC<{ category: string; sides: tSideItem[] }> = ({ category, sides }) => {
    return (
      <section className="mainDishSection">
        <h2 className="mainDishCategory">{category}</h2>
        <div className="mainDishCardsContainer">
          {sides.map((side, index) => (
            <SideDishCard
              key={index} // Again, consider using unique identifiers for keys if available
              dishName={side.name}
              dishDescription={side.description} // No need to provide an empty string, undefined is okay since it's optional
            />
          ))}
        </div>
      </section>
    );
  };
  
  export default function SidesComponent() {
    return (
      <div className="menuContainer">
        {sidesData.map((category, index) => (
          <SideDishSection key={index} category={category.name} sides={category.items} />
        ))}
      </div>
    );
  }