import { tMenuItemWithCategory, tMenuItemWithRating } from '../types';
import './mains.css'


export default function MainsComponent() {
  // Define the type for the accumulator
  type CategoryAccumulator = { [category: string]: tMenuItemWithRating[] };

  // Group dishes by category and include the rating
  const categories = mains.reduce<CategoryAccumulator>((acc, dish) => {
      const { category, name, description, rating } = dish; // Include rating in destructuring
      if (!acc[category]) acc[category] = [];
      acc[category].push({ name, description, rating }); // Use the rating from the dish
      return acc;
  }, {});

  return (
      <div className="menuContainer">
          {Object.entries(categories).map(([category, dishes], index) => (
              <MainDishSection key={index} category={category} dishes={dishes} />
          ))}
      </div>
  );
}




const MainDishCard = ({ dishName, dishDescription, rating }: { dishName: string, dishDescription: string, rating: number }) => {
  return (
      <div className="mainDishCard">
          <h3 className="mainDishName">{dishName}</h3>
          <p className="mainDishDescription">{dishDescription}</p>
          <StarRating rating={rating} /> {/* Add the StarRating component */}
      </div>
  );
};

  
const MainDishSection = ({ category, dishes }: { category: string, dishes: { name: string, description: string, rating: number }[] }) => {
    return (
      <section className="mainDishSection">
        <h2 className="mainDishCategory">{category}</h2>
        <div className="mainDishCardsContainer">
          {dishes.map((dish, index) => (
            <MainDishCard
              key={index}
              dishName={dish.name}
              dishDescription={dish.description}
              rating={dish.rating ? dish.rating : 0} 
            />
          ))}
        </div>
      </section>
    );
  };

  // Define a type for the StarRatingProps
type StarRatingProps = {
  rating: number; // a number from 1 to 5
};

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  // Create an array of 5 elements for 5 possible stars
  const stars = Array.from({ length: 5 }, (_, index) => {
    // If the current index is less than the rating, it should be a full star
    return (
      <span key={index} className={index < rating ? 'fullStar' : 'emptyStar'}>
        ★
      </span>
    );
  });

  return <div className="starRating">{stars}</div>;
};
  
  const mains: tMenuItemWithCategory[] = [{
        category: 'MILD AND FRUITY DISHES',
        name: 'Shampan',
        description: 'Diced chicken tikka or lamb tikka cooked with mixed tropical fruits, cream, coconut, almonds and pistachio simmered with tia maria, thus giving this dish a totally Caribbean flavour.',
        rating: 4
      },
      {
        category: 'MILD AND FRUITY DISHES',
        name: 'Modumoti',
        description: 'Succulent pieces of chicken tikka cooked with honey and our special butter sauce which is made with coconut, almonds, sultana and cream giving it a rich creamy texture.',
        rating: 3
      },
      {
        category: 'MILD AND FRUITY DISHES',
        name: 'Banaroshi',
        description: 'Tender pieces of chicken tikka or lamb tikka cooked with crushed pineapple, cream, almond powder, coconut powder and mango pulp.',
        rating: 3
      },
      {
        category: 'MILD AND FRUITY DISHES',
        name: 'Dabi',
        description: 'Chicken or lamb tikka cooked with a special mild sauce consisting of pistachio powder, cream, coconut, fresh cream, mango pulp and jack fruit.',
        rating: 2
      },
      {
        category: 'MILD AND FRUITY DISHES',
        name: 'Badami Bahar',
        description: 'Tandoori chicken off the bone cooked with butter, coconut, almond, sugar, pistachio powder, cream, coconut and fresh cream.',
        rating: 3
      },
      {
        category: 'MILD AND FRUITY DISHES',
        name: 'Rada (Chicken or Lamb)',
        description: 'Chicken with butter, cheese and traditional light herbs and spices; it\'s rich creamy and cheesy.',
        rating: 3
      },
      {
        category: 'MASSALA DISHES',
        name: 'Massala',
        description: `United Kingdom's best selling and most popular dish. Cooked with our own special tandoori sauce, almond powder, coconut powder, sultana, sugar and fresh cream. Available as chicken tikka, lamb tikka, tandoori king prawn, fish tikka and tandoori chicken.`,
        rating: 5
        },
        {
        category: 'TANDOORI',
            name: 'Tandoori Sizzling',
            description: 'Served sizzling on a bed of chopped onions with a crispy salad and mint sauce. Available as tandoori chicken, chicken tikka, lamb tikka, chicken shashluck, tandoori king prawns and tandoori cocktail.',
            rating: 5
        },
        {
    category: 'FUSION DISHES',
          name: 'Garlic Fish',
          description: 'Fish fillet cooked in massala sauce with coriander and garlic.',
          rating: 2
        },
        {
          category: 'FUSION DISHES',
          name: 'Haddock',
          description: 'Prime haddock fillet cooked with shallots, garlic, green peppers, using traditional Goan recipe (Medium).',
          rating: 2
        },
        {
            category: 'FUSION DISHES',
          name: 'Mass Biran',
          description: 'Garnished fried with new potato, fried onion and mustard sauce. (Medium).',
          rating: 3
        },
        {
            category: 'FUSION DISHES',
          name: 'Chingri Mirchi',
          description: 'Succulent and juicy king prawns marinated in our exclusive sauce then barbecued in the tandoori clay oven then stir cooked with onion, capsicum, aromatic spices and sweet & sour sauce.',
          rating: 3
        },
        {
          category: 'FUSION DISHES',
          name: 'Duck',
          description: 'Shredded confit of duck flavoured with tandoori spices, spring onions and orange jalfrezi sauce.',
          rating: 4
        },
        {
          category: 'Specialities',
          name: 'Sylhety',
          description: 'A popular dish all over Bangladesh. Luscious pieces of chicken, tender lamb or king prawn cooked with aromatic spices and herbs, garlic ginger, spring onion, potatoes, fresh bullet chillies. Fairly hot.',
          rating: 4
        },
        {
          category: 'Specialities',
          name: 'Joy Bangla',
          description: 'Another very popular curry from the Sylhet region of Bangladesh. Fillet breast of chicken, lamb or king prawns cooked with aromatic spices, garlic, ginger, spring onion, fresh cauliflower, methi, salt, bullet chilli, mustard seeds, fresh tomato. A very tasty dish. Fairly hot.',
          rating: 5
        },
        {
          category: 'Specialities',
          name: 'Shahi Khana',
          description: 'Highly recommended for the purveyors of traditional Bangladeshi and Indian cuisine. Succulent pieces of chicken, lamb or king prawns cooked with spices, spring onions, ginger, garlic, methi leaves, salt, mix peppers, fresh bullet chillies and fresh tomatoes. Fairly hot.',
          rating: 3
        },
        {
          category: 'Specialities',
          name: 'Chom Chom',
          description: 'Luscious pieces of chicken, lamb or king prawn cooked with fresh vegetables, mixed spices, salts, spring onions, bullet chillies, fresh tomato. It’s a home made dish, fairly hot.',
          rating: 4.5
        },
        {
          category: 'Specialities',
          name: 'Rajmoni',
          description: 'Morsels of chicken or lamb with keema kupta, ginger, spring onions, mixed peppers, salt, fresh bullet chillies and not forgetting the exclusively selected spices and herbs that make this dish a delight for the Indian culinary pundits. Fairly hot.',
          rating: 4.5
        },
        {
          category: 'Specialities',
          name: 'Mirpuri',
          description: 'Tender spring chicken or lamb cooked with aromatic spices, spring onions, garlic, salts, fresh mushrooms, mixed peppers, tomato, bullet chillies. It’s a very tasty dish. Fairly hot.',
          rating: 4
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Hasina',
          description: 'Pieces of chicken tikka or lamb tikka cubes cooked with spinach, garlic, spring onions, methi, shallots, fresh bullet chilli, sprinkled with a cheese dressing.',
          rating: 3
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Sag Specials',
          description: 'Pieces of chicken tikka or lamb tikka cubes cooked with spinach, garlic, spring onion, methi leaves and fresh coriander.',
          rating: 4
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Usmani',
          description: 'Succulent pieces of chicken, lamb or king prawn. Aphrodisiac spices, garlic, spring onions, methi, shallots, mixed peppers, bullet chilli and naga pickle. Very hot, only for the very brave.',
          rating: 5
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Nowabi Khana',
          description: 'Morsels of chicken with fine mince of lamb and boiled egg, ginger, garlic, spring onions, shallots, methi and exclusively selected spices and herbs that make this dish a delight for the Indian culinary pundits (Medium hot).',
          rating: 4.5
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Banglar Taste',
          description: 'Pieces of chicken and tender lamb cooked together with aromatic spices and herbs, garlic, ginger, spring onions, methi, shallots, fresh bullet chillies and mixed peppers. (Medium hot).',
          rating: 5
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Parsi',
          description: 'Famous Persian dish, lavishly garnished with fresh garlic, spring onions, ginger, green chillies, tomato, fresh lemon and chana dall. Chicken, lamb or king prawn. (Madras hot).',
          rating: 3
        },
        {
          category: 'HOUSE SPECIALS',
          name: 'Gaty',
          description: 'Mince of chicken kupta, boiled eggs and potato cooked with aromatic spices and herbs, garlic, ginger, spring onions, mixed peppers, fresh tomato, coriander.',
          rating: 3
        },
        {
          category: 'POPULAR DISHES',
          name: 'Karahi',
          description: 'This dish is prepared with onions, green pepper, red peppers, onions, fresh bullet chillies and whole green chilli. Available as chicken, lamb, chicken tikka, lamb tikka, king prawn, prawn. Medium hot.',
          rating: 3.5
        },
        {
          category: 'POPULAR DISHES',
          name: 'Jalfrezi',
          description: 'Cooked with mixed spices and herbs, fresh green pepper, red pepper, onions, spring onions, chopped green chillies and fresh tomato. Available as chicken, lamb, chicken tikka, lamb tikka, king prawn, prawn. Fairly hot.',
          rating: 4
        },
        {
          category: 'POPULAR DISHES',
          name: 'Balti',
          description: 'Balti dishes are the most popular dishes. Traditionally balti food is cooked in balti (metal bowl). It is prepared with special fresh herbs and spices in a thick sauce. Available as chicken, lamb, chicken tikka, lamb tikka, king prawn, prawn, chicken sag balti, kabuli, garlic chilli, banglar joy, vegetable.',
          rating: 3
        },
        {
          category: 'POPULAR DISHES',
          name: 'Biryani',
          description: 'Cooked with special fried rice and served with vegetable curry. Available as chicken, lamb, chicken tikka, lamb tikka, king prawn, prawn, vegetable, chef special.',
          rating: 4
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Curry',
          description: 'The classic dish',
          rating: 4
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Madras',
          description: 'Fairly hot',
          rating: 4
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Vindaloo',
          description: 'Classic very hot dish',
          rating: 5
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Dupiaza',
          description: 'Made with extra onions',
          rating: 3
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Rogan Josh',
          description: 'Extra tomato and onions',
          rating: 4.5
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Bhuna',
          description: 'Double cooked giving a dry taste',
          rating: 4
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Pathia',
          description: 'Sweet, sour and hot',
          rating: 3
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Korma',
          description: 'Mild dish',
          rating: 5
        },
        {
          category: 'VERY POPULAR DISHES',
          name: 'Dhansak',
          description: 'With lemon lentils, sweet, sour and hot',
          rating: 4
        },
        {
          category: 'ORGANIC VEGETARIAN DISHES',
          name: 'Ali Special',
          description: 'Mixed organic vegetables cooked fairly dry with herbs and spices, spring onions, tomato, chick peas, fresh cauliflower and potato (Medium).',
          rating: 5
        },
        {
          category: 'ORGANIC VEGETARIAN DISHES',
          name: 'Sobji Chilli Massala',
          description: 'Mixed organic vegetables cooked with mixed spices, green chillies, onions, fresh tomato and tandoori massala sauce (Madras).',
          rating: 4
        },
        {
          category: 'ORGANIC VEGETARIAN DISHES',
          name: 'Garlic Sobji',
          description: 'Vegetables cooked with aromatic spices, shallots, methi, fresh fried garlic, mixed peppers, fresh bullet chillies and tomato.',
          rating: 3
        },
        {
          category: 'ORGANIC VEGETARIAN DISHES',
          name: 'Sobji Panir',
          description: 'Mixed vegetables cooked with mixed spices, garlic, spring onions, shallots, coriander and Indian panir.',
          rating: 3.5
        }
      ]
  
  

  
  

  
