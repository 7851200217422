import { NavLink } from "react-router-dom";
import './header.css';
import banner from '../images/flag.jpg';


const navigationLinks = [
    {
        name: 'Home',
        href: '/',
    },
    {
        name: 'Starters',
        href: '/starters',
    },
    {
        name: 'Mains',
        href: '/mains',
    },
    {
        name: 'Sides',
        href: '/sides',
    },
    {
        name: 'Opening Hours',
        href: '/opening-hours',
    }
];

export default function Header() {
    return (
        <header className='header' style={{ backgroundImage: `url(${banner})` }}>
            <h1 className="siteName">Joy Bangla</h1>
            <nav className='nav'>
                <ul className='navList'>
                    {navigationLinks.map((link) => (
                        <li key={link.href} className='navItem'>
                            <NavLink 
                                to={link.href}
                                className={({ isActive }) => 
                                    isActive ? `navLink activeNavLink` : `navLink`
                                }
                            >
                                {link.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
}