import React from 'react';
import { OpeningHoursProps } from '../App';
import mapImage from '../images/map.jpg'; // Make sure the path is correct based on your project structure
import './openinghours.css';

export const OpeningHours: React.FC<OpeningHoursProps> = ({ hours, phoneNumber }) => {
  return (
    <div className="openingHours">
      <h2>Opening Hours</h2>
      <table>
        <tbody>
          {Object.entries(hours).map(([day, time], index) => (
            <tr key={index}>
              <td><strong>{day}</strong></td>
              <td>{time}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        Call us on{' '}
        <a href={`tel:${phoneNumber.replace(/\s/g, '')}`} className="phoneNumber">
          {phoneNumber}
        </a>
      </p>
      <div className="mapContainer">
        <img src={mapImage} alt="Location Map" className="locationMap" />
      </div>
    </div>
  );
};
